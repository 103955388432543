<template>
  <div class="simulator-preview">
    <div class="simulator-preview__hd" v-if="!onlyMobile && !onlyPc">
      <!-- 模拟开关 -->
      <div class="std-switch">
        <span
          :class="[{ active: switchType === 'mobile' }]"
          @click.prevent.stop="switchChange('mobile')"
        >
          手机预览
        </span>
        <span :class="[{ active: switchType === 'pc' }]" @click.prevent.stop="switchChange('pc')">
          电脑预览
        </span>
      </div>
    </div>
    <div class="simulator-preview__bd">
      <div
        class="simulator-box"
        :class="[hasMask ? 'has-mask' : '', `simulator-box--${switchType}`]"
      >
        <div class="simulator-content" v-if="switchType === 'mobile'">
          <slot name="mobile"></slot>
        </div>
        <div class="simulator-content" v-if="switchType === 'pc'">
          <slot name="pc"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SimulatorPreview',
    props: {
      onlyMobile: {
        type: Boolean,
        default: false
      },
      onlyPc: {
        type: Boolean,
        default: false
      },
      hasMask: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        switchType: 'mobile'
      };
    },
    created() {
      if (this.onlyPc) {
        this.switchChange('pc');
      }
    },
    methods: {
      switchChange(val) {
        this.switchType = val;
      }
    }
  };
</script>
<style lang="less">
  .simulator-preview {
    &__hd {
      padding-bottom: 24px;
    }

    .simulator-box {
      position: relative;
      background-position: 0 0;
      background-size: contain;
      background-repeat: no-repeat;

      &--mobile {
        width: 307.55px;
        height: 582px;
        background-image: url('../../common/images/gif/bg_mobie.png');

        .simulator-content {
          position: absolute;
          top: 74px;
          right: 7px;
          left: 7px;
          bottom: 8px;
          border-bottom-left-radius: 32px;
          border-bottom-right-radius: 32px;
          z-index: 2001;
        }
      }

      &--pc {
        width: 363.54px;
        height: 224px;
        background-image: url('../../common/images/gif/bg_pc.png');

        .simulator-content {
          position: absolute;
          top: 21px;
          right: 7.5px;
          left: 7.5px;
          bottom: 7.5px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          z-index: 2001;
        }
      }
    }
  }
</style>
