<template>
  <vh-tabs v-model="activeTab" @tab-click="handleTabClick">
    <vh-tab-pane label="观看协议" name="1">
      <viewingAgreement></viewingAgreement>
    </vh-tab-pane>
    <vh-tab-pane label="开播协议" name="2" v-if="$route.query.type != 5 && webinarState != 4">
      <broadcastingAgreement></broadcastingAgreement>
    </vh-tab-pane>
  </vh-tabs>
</template>

<script>
  import viewingAgreement from './components/viewingAgreement.vue'; //观看协议
  import broadcastingAgreement from './components/broadcastingAgreement.vue'; //开播协议
  export default {
    components: {
      viewingAgreement,
      broadcastingAgreement
    },
    props: {
      webinarState: {
        default: ''
      }
    },
    data() {
      return {
        activeTab: '1'
      };
    },
    created() {
      this.activeTab = this.$route.query.tab || '1';
    },
    methods: {
      handleTabClick() {}
    }
  };
</script>

<style></style>
