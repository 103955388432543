<template>
  <div class="page-container">
    <!-- 标题插槽 -->
    <slot name="title"></slot>
    <div class="page-container__body">
      <!-- 内容插槽 -->
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageContainer'
  };
</script>
<style lang="less">
  .page-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .page-container__body {
      width: 100%;
      flex: 1;
      background-color: #fff;
      display: flex;

      .null-page {
        margin-top: 0 !important;
        align-self: center;
        margin: 0 auto;
      }

      .page-content {
        flex: 1;
        align-self: flex-start;
      }

      > .page-content--list-box {
        padding: 24px;
      }
      > .page-create-box {
        padding: 48px 16px 0 48px;
      }
    }
  }

  // 有tabs
  .page-container.has-tabs {
    .page-container__body {
      width: 100%;
      padding: 0;

      & > .vh-tabs {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .vh-tabs__header {
          height: 48px;
          line-height: 48px;
          margin: 0;

          .vh-tabs__item {
            .saasicon_help_m {
              color: #999;
            }
          }
        }

        .vh-tabs__content {
          width: 100%;
          flex: 1;
          display: flex;
          padding: 0;

          .vh-tab-pane {
            width: 100%;
            flex: 1;
            // display: flex;
            // align-items: center;
            // justify-content: center;

            .page-content--list-box {
              padding: 16px 24px 24px 24px;
            }

            .page-create-box {
              flex: 1 !important;
              padding: 48px 16px 24px 48px;
            }
          }
        }
      }
    }
  }
</style>
